import React, { useContext } from 'react'
import {
  Grid,
  Typography,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@material-ui/core'
import Input from 'BaseComponents/Input'
import DeleteIcon from '@material-ui/icons/Delete'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import PhoneFormat from 'BaseComponents/PhoneFormat'
import toast from 'react-hot-toast'
import { useForm } from 'Hooks/validator'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  saveClinic,
  deleteClinic,
  getClinicById,
} from 'Redux/ClinicTable/clinicTable.actions'
// import Notes from '../Notes'
import { states } from 'Helpers/states'
import './style.css'
import { useEffect, useState } from 'react'
import Dropdown from 'BaseComponents/Dropdown'
import { PageNum } from 'Context'
import DobPicker from 'BaseComponents/DatePickerNew'
// import { PageNum } from 'Context'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  searchFormContainer: {
    height: '100%',
  },
  paperStyle: {
    padding: '1.5rem',
    borderRadius: '6px',
    height: '100%',
  },
  heading: {
    fontWeight: 500,
    paddingTop: '1rem',
  },
}))

const emr = [
  { name: 'eCw', value: 'eCw' },
  { name: 'Allscripts', value: 'Allscripts' },
  { name: 'Athena health', value: 'Athena health' },
  { name: 'AdvancedMD', value: 'Advanced MD' },
  { name: 'EPIC', value: 'EPIC' },
  { name: 'PracticeFusion', value: 'PracticeFusion' },
  { name: 'Kareo', value: 'Kareo' },
  { name: 'Greenway', value: 'Greenway' },
  { name: 'NextGen', value: 'NextGen' },
  { name: 'Cerner', value: 'Cerner' },
  { name: 'Other', value: 'Other' },
]

const bill = [
  { name: 'Inside billing', value: 'Inside billing' },
  { name: 'Outside management company', value: 'Outside management company' },
  { name: 'EMR Vendor', value: 'EMR Vendor' },
  { name: 'AthenaHealth', value: 'AthenaHealth' },
  { name: 'eCw', value: 'eCw' },
  { name: '99 Management', value: '99 Management' },
]

const marketing = [
  { name: '-- None --', value: '' },
  { name: 'Initial sales campaign', value: 'Initial sales campaign' },
  { name: 'Nurture Campaign', value: 'Nurture Campaign' },
]

const lead = [
  { name: 'Prospect', value: 'Prospect' },
  { name: 'Qualified', value: 'Qualified' },
  { name: 'Solution', value: 'Solution' },
  { name: 'Proposal', value: 'Proposal' },
  { name: 'Closed', value: 'Closed' },
  { name: 'Nurturing', value: 'Nurturing' },
  { name: 'Disqualified', value: 'Disqualified' },
  { name: 'Lost', value: 'Lost' },
]

const priorAuth = [
  { name: 'Inside', value: 'Inside' },
  { name: 'Billing Company', value: 'Billing Company' },
]

const coding = [
  { name: 'Physician', value: 'Physician' },
  { name: 'Inside Biller', value: 'Inside Biller' },
  { name: 'Outside Biller', value: 'Outside Biller' },
]

const ClinicForm = (props) => {
  const classes = useStyles()
  const id = localStorage.getItem('userId')
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const [open, setOpen] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [users, setUsers] = useState([])
  const [isDelete, setIsDelete] = useState(false)
  const { isNewForm, setClinicId } = useContext(PageNum)
  const [speciality, setSpeciality] = useState([])
  const [clinicArray, setClinicArray] = useState([])

  const phoneFormat = () => {
    if (/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.test(values.phone) === false) {
      setIsPhoneValid(true)
    }
  }

  const defaultValues = {
    clinicId: 0,
    clinicName: '',
    frontdeskName: '',
    officeManagerName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: 'TX',
    zip: '',
    salesPerson: 0,
    status: '',
    pysicianName: '',
    emrName: '',
    billing: '',
    estMonthlyRevenue: '',
    speciality: '',
    opportunity: '',
    priorAuth: '',
    coding: '',
    PullDate: '',
    lastVisit: '',
    durationRelationship: '',
    challanges: '',
    marketingCampaign: '',
    owner: '',
    active: 1,
    userId: id,
  }

  const { useInput, isValid, values, setValues } = useForm(defaultValues)

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }
    setIsSaveClicked(true)
    if (isValid) {
      props.saveClinic({ ...values, flag: null })
    }
    setIsSaveClicked(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleNo = () => {
    setOpen(false)
    setIsSaveClicked(false)
  }

  const handleYes = () => {
    props.saveClinic({ ...values, flag: true })
    setOpen(false)
  }

  const resetForm = () => {
    setValues(defaultValues)
  }

  const findClinic = () => {
    return clinicArray.find((element) => element === values.clinicId)
  }

  const handleNext = () => {
    let index = clinicArray.indexOf(findClinic())
    props.getClinicById(clinicArray[index + 1])
    setClinicId(clinicArray[index + 1])
  }

  const handlePrev = () => {
    let index = clinicArray.indexOf(findClinic())
    props.getClinicById(clinicArray[index - 1])
    setClinicId(clinicArray[index - 1])
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.Users &&
      props.clinicData.Users.response
    ) {
      // console.log('data', props.clinicData)
      let usersArray = props.clinicData.Users.response.data
      setUsers(
        usersArray.map((user) => {
          return {
            name: user.firstName + ' ' + user.lastName,
            value: user.userId,
          }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Users.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.SpecialityOptions &&
      props.clinicData.SpecialityOptions.response
    ) {
      let specialityArray = props.clinicData.SpecialityOptions.response.data
      setSpeciality(
        specialityArray.map((speciality) => {
          return { name: speciality.value, value: speciality.value }
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Users.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.ClinicDetails &&
      props.clinicData.ClinicDetails.response
    ) {
      let id = values.clinicId
      if (props.clinicData.ClinicDetails.response.responseCode === 0) {
        if (id === 0) {
          toast.success('Clinic added successfully')
          setIsSaveClicked(false)
          let id = props.clinicData.ClinicDetails.response.data.id
          props.getClinicById(id)
          props.getClinicId(id)
          // props.clinicData.ClinicDetails = {}
        } else {
          toast.success('Clinic updated successfully')
          setIsSaveClicked(false)
        }
      } else if (props.clinicData.ClinicDetails.response.responseCode === 132) {
        setIsSaveClicked(false)
        setOpen(true)
        setErrMessage('Phone number or email id already exists')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.ClinicDetails.response])

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.ClinicById &&
      props.clinicData.ClinicById.response
    ) {
      let formData = props.clinicData.ClinicById.response.data
      setValues({
        ...values,
        ...{
          clinicId: formData.clinicId,
          clinicName: formData.clinicName,
          frontdeskName: formData.frontdeskName,
          officeManagerName: formData.officeManagerName,
          phone: formData.phone,
          email: formData.email,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          salesPerson: formData.salesPerson,
          status: formData.status,
          pysicianName: formData.pysicianName,
          emrName: formData.emrName,
          billing: formData.billing,
          estMonthlyRevenue: formData.estMonthlyRevenue,
          speciality: formData.speciality,
          opportunity: formData.opportunity,
          priorAuth: formData.priorAuth,
          coding: formData.coding,
          durationRelationship: formData.durationRelationship,
          challanges: formData.challanges,
          marketingCampaign: formData.marketingCampaign,
          owner: formData.owner,
          active: formData.active,
          userId: formData.userId,
          pullDate: formData.pullDate,
          lastVisit: formData.lastVisit,
          createdDate: formData.createdDate,
          lastContact: formData.lastContact,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.ClinicById])

  const handleDeleteNo = () => {
    setIsDelete(false)
  }
  const handleDeleteYes = () => {
    DeleteClinic()
    setIsDelete(false)
  }

  const handleDelete = () => {
    setIsDelete(true)
  }

  const DeleteClinic = () => {
    let id = values.clinicId
    props.deleteClinic(id)
  }

  useEffect(() => {
    if (
      props.clinicData &&
      props.clinicData.Deleted &&
      props.clinicData.Deleted.response
    ) {
      if (props.clinicData.Deleted.response.responseCode === 0) {
        toast.success('Clinic deleted successfully')
        props.backFunction()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clinicData.Deleted.response])

  useEffect(() => {
    if (!isNewForm) {
      let clinics = props.clinicData.ClinicsList.response.data.results
      setClinicArray(
        clinics.map((clinic) => {
          return clinic.clinicId
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit} className="clinic__form">
        <Typography variant="h6" className={classes.heading}>
          <Button onClick={props.backFunction}>
            <Icon>arrow_back</Icon>
          </Button>
          Clinic Details
        </Typography>
        <div className="form--innerWrapper">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Input
                label="Clinic Name"
                type="text"
                required
                {...useInput('clinicName', {
                  isRequired: {
                    value: true,
                    message: 'Enter a valid name',
                  },
                  isLength: {
                    value: { max: 100 },
                    message: 'Max Length is 100',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9-'.,#&()\s]+$/,
                    message: 'Enter a valid name',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Address"
                type="text"
                {...useInput('address', {
                  isLength: {
                    value: { max: 100 },
                    message: 'Max Length is 100',
                  },
                  matches: {
                    value: /^(?=.,*[a-zA-Z0-9])[a-zA-Z0-9-'.,@#\s]+$/,
                    message: 'Please enter a valid address',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Input
                label="City"
                type="text"
                {...useInput('city', {
                  isLength: {
                    value: { max: 30 },
                    message: 'Max Length is 30',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z])[a-zA-Z0-9-'.\s]+$/,
                    message: 'Enter valid name',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="State"
                options={states}
                {...useInput('state', {
                  isRequired: {
                    value: true,
                    message: 'Select an option',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                label="Zip"
                type="text"
                {...useInput('zip', {
                  matches: {
                    value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                    message: 'Enter a valid zip code',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <PhoneFormat
                required={isPhoneValid}
                id={'phone'}
                type="text"
                label="Phone"
                onKeyUp={phoneFormat}
                {...useInput('phone', {
                  isRequired: {
                    value: true,
                    message: 'Enter a valid phone number',
                  },
                  matches: {
                    value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                    message: 'Enter a valid phone number',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Email"
                type="email"
                {...useInput('email', {
                  isLength: {
                    value: { max: 50 },
                    message: 'Maximum length is 50',
                  },
                  matches: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Enter a valid email',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Input
                label="Frontdesk Name"
                type="text"
                {...useInput('frontdeskName', {
                  isLength: {
                    value: { max: 50 },
                    message: 'Max Length is 50',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z])[a-zA-Z0-9-'\s]+$/,
                    message: 'Enter valid name',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Office Manager Name"
                type="text"
                {...useInput('officeManagerName', {
                  isLength: {
                    value: { max: 50 },
                    message: 'Max Length is 50',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z])[a-zA-Z0-9-'\s]+$/,
                    message: 'Enter valid name',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Input
                label="Physician Name"
                type="text"
                required
                {...useInput('pysicianName', {
                  isRequired: {
                    value: true,
                    message: 'Enter a valid name',
                  },
                  isLength: {
                    value: { max: 50 },
                    message: 'Max Length is 50',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z])[a-zA-Z0-9-'.,\s]+$/,
                    message: 'Enter valid name',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="EMR name"
                options={emr}
                {...useInput('emrName', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Sales Person"
                options={users}
                required={true}
                {...useInput('salesPerson', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Billing"
                options={bill}
                {...useInput('billing', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label="Length of current billing relationship"
                type="text"
                {...useInput('durationRelationship', {
                  isLength: {
                    value: { max: 100 },
                    message: 'Max Length is 100',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9-'.,&#@%\s]+$/,
                    message: 'Enter valid text',
                  },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Input
                label="Challenges"
                type="text"
                {...useInput('challanges', {
                  isLength: {
                    value: { max: 200 },
                    message: 'Max Length is 200',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9-'.,&#@%!()+:;\s]+$/,
                    message: 'Enter valid text',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* <NewDatePicker
                label="Pull Date"
                //  value={selectedPullDate}
                {...useInput('pullDate', {
                  isRequired: {
                    value: true,
                    message: 'Select an option',
                  },
                })}
              /> */}
              <DobPicker
                label="Pull Date"
                {...useInput('pullDate', {
                  // isRequired: {
                  //   value: false,
                  //   message: 'Select an option',
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Input
                label="Opportunity"
                type="text"
                {...useInput('opportunity', {
                  isLength: {
                    value: { max: 200 },
                    message: 'Max Length is 200',
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DobPicker
                label="Last Visit"
                {...useInput('lastVisit', {
                  // isRequired: {
                  //   value: true,
                  //   message: 'Select an option',
                  // },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Input
                label="Expected monthly revenue"
                type="text"
                {...useInput('estMonthlyRevenue', {
                  isLength: {
                    value: { max: 200 },
                    message: 'Max Length is 200',
                  },
                  matches: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9-'.,&#@%!()+:;\s]+$/,
                    message: 'Enter valid number',
                  },
                })}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Speciality"
                options={speciality}
                {...useInput('speciality', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Prior Auth"
                options={priorAuth}
                {...useInput('priorAuth', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={4}></Grid> */}
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Marketing Campaign"
                options={marketing}
                {...useInput('marketingCampaign', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Lead Status"
                options={lead}
                required={true}
                {...useInput('status', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                label="Coding"
                options={coding}
                {...useInput('coding', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid item xs={12} sm={12} className="formButtons--wrapper">
              <div className="formButtons">
                {!isNewForm ? (
                  <>
                    <IconButton
                      onClick={handlePrev}
                      variant="outlined"
                      color="primary"
                      style={{
                        margin: '6% 6px',
                      }}
                      className="prevButton"
                    >
                      <NavigateBeforeIcon fontSize="large" />
                    </IconButton>
                  </>
                ) : null}
                <div className="centerButtons">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={resetForm}
                    style={{ margin: '6% 6px' }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ margin: '6% 6px' }}
                    className="btn--white"
                    disabled={isSaveClicked}
                    onSubmit={handleSubmit}
                  >
                    Save
                  </Button>
                  {values.clinicId !== 0 ? (
                    <Button
                      onClick={handleDelete}
                      variant="outlined"
                      color="primary"
                      startIcon={<DeleteIcon />}
                      style={{
                        margin: '6% 6px',
                      }}
                    >
                      Delete
                    </Button>
                  ) : null}
                </div>
                {!isNewForm ? (
                  <>
                    <IconButton
                      onClick={handleNext}
                      variant="outlined"
                      color="primary"
                      style={{
                        margin: '6% 6px',
                      }}
                    >
                      <NavigateNextIcon fontSize="large" />
                    </IconButton>
                  </>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Dialog
            open={isDelete}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Are you sure you want to delete?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autofocus onClick={handleDeleteNo}>
                No
              </Button>
              <Button onClick={handleDeleteYes}>Yes</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Do you want to continue?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNo}>No</Button>
              <Button onClick={handleYes} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clinicData: state.clinicTable,
})

const mapDispatchToProps = (dispatch) => ({
  saveClinic: (values) => dispatch(saveClinic(values)),
  deleteClinic: (values) => dispatch(deleteClinic(values)),
  getClinicById: (values) => dispatch(getClinicById(values)),
})

ClinicForm.propTypes = {
  saveClinic: PropTypes.func,
  deleteClinic: PropTypes.func,
  clinicData: PropTypes.object,
  getClinicById: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(ClinicForm)
