import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as utlAjax } from 'Utils'
import { LOGIN, REQUEST_CANCEL, LOGOUT } from './login.types'
import { getLoginResponse, logoutApiResponse } from './login.actions'
import environment from 'environments/environment'
const { baseUrl } = environment

const loginEpic = {}

loginEpic.loginApi = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'Content-type': 'application/json',
        },
        url: `${baseUrl}/crm-portal-api/v1/authenticate/login`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getLoginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.logoutApi = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/authenticate/logout`,
        method: 'GET',
        body: action.payload,
      }).pipe(map((response) => logoutApiResponse(response))),
    ),
  )

export default loginEpic
