import {
  // GET_CLINICS,
  // GET_CLINICS_RESPONSE,
  CLINICS,
  CLINICS_RESPONSE,
  LIMIT,
  PAGE,
  SORT_FIELD,
  ORDER,
  CLINIC_BY_ID,
  CLINIC_BY_ID_RES,
  SAVE_CLINIC,
  SAVE_CLINIC_RES,
  GET_NOTES,
  GET_NOTES_RES,
  SAVE_NOTE,
  SAVE_NOTE_RES,
  GET_USERS,
  GET_USERS_RES,
  SEARCH_VALUES,
  DELETE_CLINIC,
  DELETE_CLINIC_RES,
  GET_SPECIALITY,
  GET_SPECIALITY_RES,
} from './clinicTable.types'

// export const getClinics = (values) => ({
//   type: GET_CLINICS,
//   payload: values,
// })

// export const getClinicsResponse = (resp) => ({
//   type: GET_CLINICS_RESPONSE,
//   payload: resp,
// })

export const fetchClinics = (params) => ({
  type: CLINICS,
  payload: params,
})

export const fetchClinicsResponse = (resp) => ({
  type: CLINICS_RESPONSE,
  payload: resp,
})

export const setLimit = (number) => ({
  type: LIMIT,
  payload: number,
})
export const setPage = (number) => ({
  type: PAGE,
  payload: number,
})

export const setSortField = (field) => ({
  type: SORT_FIELD,
  payload: field,
})

export const setOrder = (order) => ({
  type: ORDER,
  payload: order,
})

export const setSearchValues = (values) => ({
  type: SEARCH_VALUES,
  payload: values,
})

export const saveClinic = (data) => ({
  type: SAVE_CLINIC,
  payload: data,
})

export const saveClinicResponse = (res) => ({
  type: SAVE_CLINIC_RES,
  payload: res,
})

export const deleteClinic = (data) => ({
  type: DELETE_CLINIC,
  payload: data,
})

export const saveDeletedResponse = (res) => ({
  type: DELETE_CLINIC_RES,
  payload: res,
})

export const getClinicById = (id) => ({
  type: CLINIC_BY_ID,
  payload: id,
})

export const getClinicByIdResponse = (res) => ({
  type: CLINIC_BY_ID_RES,
  payload: res,
})

export const getNotes = (id) => ({
  type: GET_NOTES,
  payload: id,
})

export const getNotesRes = (res) => ({
  type: GET_NOTES_RES,
  payload: res,
})

export const saveNote = (id) => ({
  type: SAVE_NOTE,
  payload: id,
})

export const saveNoteRes = (res) => ({
  type: SAVE_NOTE_RES,
  payload: res,
})

export const getUsers = (data) => ({
  type: GET_USERS,
  payload: data,
})

export const getUsersResponse = (res) => ({
  type: GET_USERS_RES,
  payload: res,
})

export const getSpeciality = (data) => ({
  type: GET_SPECIALITY,
  payload: data,
})

export const getSpecialityResponse = (res) => ({
  type: GET_SPECIALITY_RES,
  payload: res,
})
