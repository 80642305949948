import React, { useEffect, useState } from 'react'
import { useForm } from 'Hooks/validator'
import Button from 'BaseComponents/Button'
import Input from 'BaseComponents/Input'
import { getLogin } from 'Redux/Login/login.actions'
import PropTypes from 'prop-types'
import primroseHealthLogo from '../../assets/images/primrose-brand-logo-sm-0.5x.png'
import './style.css'
import { connect } from 'react-redux'

const LoginForm = (props) => {
  const [err, setErr] = useState(null)
  const defaultValues = {
    userName: '',
    password: '',
  }
  const { values, useInput, isValid } = useForm(defaultValues)

  const handleSubmit = (e) => {
    e.preventDefault()
    if(isValid) {
      props.getLogin({
        userName: values.userName,
        password: values.password,
      })
    }
  }

  useEffect(() => {
    // console.log(props.Login.loginResponse.status);
    // console.log(props.Login.loginResponse.response);
    if (props.Login.loginResponse.status === 200) {
      if (props.Login.loginResponse.response) {
        const data = props.Login.loginResponse.response
        if (data) {
          if (data.responseCode === 0) {
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('userId', data.userId)
            localStorage.setItem('userName', data.username)
            window.location.href = '/clinics'
          }
          else if (data.responseCode === 125) {
            console.log(data.error.message)
            setErr(data.error.message["125"])
          }
        }
      }
    }
  }, [props.Login.loginResponse])

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <div className="clinicLogin">
      <header className="clinicLogin__header">
        <div className="clinicLogin__header__bar">
          <a href="#0">
            <img
              src={primroseHealthLogo}
              alt="primrose-health-logo"
              className="clinicLogin__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="clinicLogin__main">
        <section className="clinicLogin__section">
          <div>
            <h1 className="clinicLogin__grid__subheading">
              Primrose Health CRM
            </h1>
          </div>

          <div>
            <form onSubmit={handleSubmit} className="clinicLogin__form">
              <div className='clinicLogin__inputField'>
                <Input
                  margin="normal"
                  autoFocus
                  type="text"
                  label="Username"
                  variant="outlined"
                  className='login_input'
                  error={ err ? true : false}
                  {...useInput('userName', {
                    isRequired: {
                      value: true,
                      message: 'Enter a valid Username',
                    },
                  })}
                />

                <Input
                  margin="normal"
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className='login_input'
                  error={ err ? true : false}
                  autoComplete="current-password"
                  helperText={err}
                  {...useInput('password', {
                    isRequired: {
                      value: true,
                      message: 'Enter a valid Password',
                    },
                  })}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    btname="Login"
                    className="clinicLogin__submitBtn btn--white"
                  />
                </div>
                
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  )
}

const mapStateToProps = (state) => ({
  Login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  getLogin: (values) => dispatch(getLogin(values)),
})

LoginForm.propTypes = {
  getLogin: PropTypes.func,
  Login: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
