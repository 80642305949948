import React, { useEffect, useState } from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import FormControl from '@material-ui/core/FormControl'
// import MaskedInput from "react-text-mask";
// import PropTypes from "prop-types";
//import TextField from '@material-ui/core/TextField';
/** @file Manages reusable Date Picker
 * @author Akhil
 */

/**
 * Mask for date picker
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */
// function TextMaskCustom(props) {
//   const { inputRef, ...other } = props;

//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'_'}
//       showMask

//     />
//   );
// }

// TextMaskCustom.propTypes = {
//   inputRef: PropTypes.func.isRequired,
// };
/**
 * Format Date
 */
/**
 * Reusable Date Picker
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */

function DobPicker(props) {
  const [value, setValues] = useState(props.value ? props.value : null)
  //   const [maxDate, setMaxDate] = useState(props.maxDate ? props.maxDate : null)
  /**
   * rendering the props value
   */
  useEffect(() => {
    if (props.value === null || props.value === '') {
      setValues(null)
    } else {
      setValues(props.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])
  //   useEffect(() => {
  //     if (props.value === null || props.value === '') {
  //       setMaxDate(null)
  //     } else {
  //       setMaxDate(props.maxDate)
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [props.maxDate])
  useEffect(() => {
    if (props.clearDob === true) {
      handleInput(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearDob])
  /**
   * onChange of datepicker
   */
  const handleInput = (event) => {
    if (event === null) {
      setValues(null)
    }
    // setValues(formatDate(event))
    if (props.onChange) {
      const target = { type: 'date', checked: null, value: event }
      props.onChange({ target })
    }
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          defaultValue={null}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          //   TextFieldComponent={TextFieldComponent}

          // InputProps={{
          //   inputComponent: TextMaskCustom,
          // }}
          InputLabelProps={{
            shrink: true,
          }}
          minDate={'1800-01-01'}
          //   maxDate={maxDate}
          {...props}
          // showMask
          // error={false}
          // helperText={''}
          onChange={handleInput}
          variant={'dialog'}
          inputVariant={props.inputVariant ? props.inputVariant : 'standard'}
          value={value}
          format="MM/dd/yyyy"
          placeholder={'MM/DD/YYYY'}
          showTodayButton={true}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}

export default DobPicker
