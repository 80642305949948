import React, { useState } from 'react'
import { createContext } from 'react'

export const PageNum = createContext()

const Context = ({ children }) => {
  const [page, setPage] = useState(0)
  const [flag, setFlag] = useState(false)
  const [condition, setCondition] = useState(2)
  const [isNewForm, setIsNewForm] = useState(false)
  const [clinicId, setClinicId] = useState(null)
  const [selectedItem, setselectedItem] = useState({
    clinicName: true,
    address: false,
    city: true,
    st: true,
    zip: false,
    phone: true,
    email: false,
    frontdeskName: true,
    salesPerson: true,
    officeManagerName: false,
    pysicianName: false,
    emrName: false,
    billing: true,
    status: true,
    speciality: false,
    estMonthlyRevenue: false,
    opportunity: false,
    priorAuth: false,
    coding: false,
    marketingCampaign: false,
    createdDate: false,
    lastContact: false,
  })

  const [clinicIds, setClinicIds] = useState([])
  return (
    <PageNum.Provider
      value={{
        page,
        setPage,
        flag,
        setFlag,
        selectedItem,
        setselectedItem,
        condition,
        setCondition,
        clinicIds,
        setClinicIds,
        isNewForm,
        setIsNewForm,
        clinicId,
        setClinicId,
      }}
    >
      {children}
    </PageNum.Provider>
  )
}

export default Context
