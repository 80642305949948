import {
  // GET_CLINICS_RESPONSE,
  CLINICS_RESPONSE,
  LIMIT,
  PAGE,
  ORDER,
  SORT_FIELD,
  SAVE_CLINIC_RES,
  CLINIC_BY_ID_RES,
  GET_NOTES_RES,
  SAVE_NOTE_RES,
  GET_USERS_RES,
  SEARCH_VALUES,
  DELETE_CLINIC_RES,
  GET_SPECIALITY_RES,
} from './clinicTable.types'

const INITIAL_STATE = {
  // AllClinics: [],
  ClinicsList: [],
  PAGE: 1,
  LIMIT: 10,
  ORDER: true,
  SORTFIELD: '',
  ClinicDetails: {},
  ClinicById: {},
  Notes: null,
  NoteDetails: null,
  Users: [],
  searchValues: {},
  Deleted: {},
  SpecialityOptions: [],
}

const clinicTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case GET_CLINICS_RESPONSE: {
    //   return { ...state, ...{ AllClinics: action.payload } }
    // }
    case CLINICS_RESPONSE: {
      return { ...state, ...{ ClinicsList: action.payload } }
    }
    case PAGE: {
      return { ...state, ...{ PAGE: action.payload } }
    }
    case LIMIT: {
      return { ...state, ...{ LIMIT: action.payload } }
    }
    case SORT_FIELD: {
      return { ...state, ...{ SORTFIELD: action.payload } }
    }
    case ORDER: {
      return { ...state, ...{ ORDER: action.payload } }
    }
    case SEARCH_VALUES: {
      return { ...state, ...{ searchValues: action.payload } }
    }
    case SAVE_CLINIC_RES: {
      return { ...state, ...{ ClinicDetails: action.payload } }
    }
    case DELETE_CLINIC_RES: {
      return { ...state, ...{ Deleted: action.payload } }
    }
    case CLINIC_BY_ID_RES: {
      return { ...state, ...{ ClinicById: action.payload } }
    }
    case GET_NOTES_RES: {
      return { ...state, ...{ Notes: action.payload } }
    }
    case SAVE_NOTE_RES: {
      return { ...state, ...{ NoteDetails: action.payload } }
    }
    case GET_USERS_RES: {
      return { ...state, ...{ Users: action.payload } }
    }
    case GET_SPECIALITY_RES: {
      return { ...state, ...{ SpecialityOptions: action.payload } }
    }

    default:
      return state
  }
}

export default clinicTableReducer
