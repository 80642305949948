import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Grid, Icon } from '@material-ui/core'
import primroseHealthLogo from '../../assets/images/primrose-brand-logo-sm-0.5x.png'
import Searchbar from 'Components/Clinics/Searchbar'
import ClinicsTable from 'Components/Clinics/ClinicsTable'
import ClinicForm from 'Components/Clinics/ClinicForm'
import { makeStyles } from '@material-ui/core/styles'
import IdleTimerContainer from 'Utils/IdleTimerContainer'
import Notes from 'Components/Clinics/Notes'
import './style.css'
import PropTypes from 'prop-types'
import {
  setSearchValues,
  getClinicById,
  fetchClinics,
  getUsers,
  getSpeciality,
} from 'Redux/ClinicTable/clinicTable.actions'
import { logoutApi } from 'Redux/Login/login.actions'
import { useEffect } from 'react'
import { useContext } from 'react'
import { PageNum } from 'Context'

const useStyles = makeStyles({
  root: {
    padding: '0',
    height: 'calc(100vh - 71px)',
    backgroundColor: '#FFFFFF',
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    justifyContent: 'start',
    gap: '0',
    flexWrap: 'nowrap ',
    height: '100%',
  },
  searchBarCard: {
    boxShadow: 'none !important',
    borderRadius: '15px',
  },
  grid1: {
    minWidth: '280px',
    // marginBottom: '1rem',
    '@media (max-width: 1440px)': {
      minWidth: '250px',
    },
  },
  Heading: {
    fontSize: '24px',
    fontWeight: '600',
    paddingLeft: '20px',
    color: '#3C4257',
  },
  table: {
    position: 'relative',
    width: 'calc(110vh -238px)',
  },
  plusButton: {
    position: 'absolute',
    bottom: '10%',
    right: '5%',
  },
  plusIcon: {
    display: 'flex',
    marginTop: '18px',
    fontSize: '3rem',
  },
})

const Clinics = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const {
    setFlag,
    page,
    condition,
    setCondition,
    setIsNewForm,
    clinicId,
    setClinicId,
  } = useContext(PageNum)

  const defaultValues = {
    salesPerson: '',
    status: '',
    clinic: '',
    city: '',
    zip: '',
    phone: '',
    AddedDate: '',
    NotAddedDate: '',
    emrName: '',
    billing: '',
    limit: props.clinicData.LIMIT,
    ascending: true,
    page: page + 1,
    sortField: '',
  }
  useEffect(() => {
    props.setSearchValues(defaultValues)
    // setTimeout(() => {
    // setloading(true)
    // }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [values, setValues] = useState(defaultValues)

  const handleClick = () => {
    setCondition(1)
    setClinicId(null)
    setIsNewForm(true)
    props.clinicData.ClinicById = {}
    props.clinicData.Notes = []
  }

  const backFunction = () => {
    history.push('/clinics')
    setClinicId(null)
    setIsNewForm(false)
    setCondition(2)
    setFlag(true)
    setValues({
      ...props.clinicData.searchValues,
      limit: props.clinicData.LIMIT,
      ascending: props.clinicData.ORDER,
      page: props.clinicData.PAGE,
      sortField: props.clinicData.SORTFIELD,
    })
    props.clinicData.Deleted.response = {}
    props.clinicData.ClinicDetails = {}
    // props.clinicData.ClinicById.response.data = {}
    // props.clinicData.Notes.response.data = []
  }

  const handleApiCall = (status, id) => {
    setCondition(status)
    props.getClinicById(id)
    setClinicId(id)
  }

  const handleLogout = () => {
    window.location.href = '/login'
    props.logoutApi()
    localStorage.clear()
  }

  useEffect(() => {
    props.getUsers()
    props.getSpeciality()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mainContainer">
      <IdleTimerContainer handleLogout={handleLogout} />
      <div className="clinic__header">
        <div className="clinic_header_logo">
          <img
            src={primroseHealthLogo}
            alt="primrose-health-logo"
            className="clinicLogin__header__logo"
          />
        </div>
        <div className="logout_btn">
          <Button
            color="primary"
            variant="contained"
            className="btn--white"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </div>
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={2} sm={2} className={classes.grid1}>
            <Searchbar />
          </Grid>

          {condition === 1 ? (
            <Grid container spacing={1}>
              <Grid
                item
                xs={5}
                sm={7}
                style={{ height: '100%' }}
                // className={classes.grid1}
              >
                <ClinicForm
                  backFunction={backFunction}
                  getClinicId={(e) => setClinicId(e)}
                  clinicId={clinicId}
                />
                {/* <div className="navButtonGroup"> */}
                {/* <Button
                    variant="outlined"
                    color="primary"
                    className="navButton"
                    style={{ margin: '6% 6px' }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="outlined"
                    color="primary"
                    className="navButton"
                    style={{ margin: '6% 6px' }}
                  >
                    Next
                  </Button> */}
                {/* </div> */}
              </Grid>

              <Grid
                item
                xs={5}
                sm={5}
                // className={classes.grid1}
              >
                <Notes clinicId={clinicId} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              sm={10}
              style={{ height: '100%' }}
              className={classes.table}
            >
              <ClinicsTable
                fetClinics={props.fetClinics}
                values={values}
                setStatus={(status, id) => handleApiCall(status, id)}
              />

              <Button className={classes.plusButton} onClick={handleClick}>
                <Icon color="primary" className={classes.plusIcon}>
                  add_circle
                </Icon>
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clinicData: state.clinicTable,
  logData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  getClinicById: (values) => dispatch(getClinicById(values)),
  logoutApi: (values) => dispatch(logoutApi(values)),
  fetchClinics: (values) => dispatch(fetchClinics(values)),
  getUsers: (values) => dispatch(getUsers(values)),
  setSearchValues: (values) => dispatch(setSearchValues(values)),
  getSpeciality: (values) => dispatch(getSpeciality(values)),
})

Clinics.propTypes = {
  clinicData: PropTypes.object,
  getClinicById: PropTypes.func,
  logData: PropTypes.object,
  logoutApi: PropTypes.func,
  fetchClinics: PropTypes.func,
  getUsers: PropTypes.func,
  setSearchValues: PropTypes.func,
  getSpeciality: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Clinics)
