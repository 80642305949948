import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax as utlAjax } from 'Utils'
import {
  // GET_CLINICS,
  CLINICS,
  SAVE_CLINIC,
  REQUEST_CANCEL,
  CLINIC_BY_ID,
  GET_NOTES,
  SAVE_NOTE,
  GET_USERS,
  DELETE_CLINIC,
  GET_SPECIALITY,
} from './clinicTable.types'

import {
  // getClinicsResponse,
  fetchClinicsResponse,
  saveClinicResponse,
  getClinicByIdResponse,
  getNotesRes,
  saveNoteRes,
  getUsersResponse,
  saveDeletedResponse,
  getSpecialityResponse,
} from './clinicTable.actions'
import environment from 'environments/environment'
const { baseUrl } = environment

const ClinicTableEpic = {}

// ClinicTableEpic.getAllClinics = (action$) =>
//   action$.pipe(
//     ofType(GET_CLINICS),
//     switchMap((action) =>
//       utlAjax({
//         url: `${baseUrl}/crm-portal-api/v1/clinic/list/all`,
//         method: 'GET',
//       }).pipe(
//         map(
//           (response) => getClinicsResponse(response),
//           takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//         ),
//       ),
//     ),
//   )

ClinicTableEpic.getClinics = (action$) =>
  action$.pipe(
    ofType(CLINICS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/clinic/list/range?salesPerson=${
          action.payload.salesPerson
        }&status=${action.payload.status}&city=${
          action.payload.city
        }&clinic=${action.payload.clinic.replace(/\s+/g, ' ').trim()}&zip=${
          action.payload.zip
        }&emrName=${action.payload.emrName}&billing=${
          action.payload.billing
        }&phone=${action.payload.phone}&AddedDate=${
          action.payload.AddedDate
        }&NotAddedDate=${action.payload.NotAddedDate}&pagination=1&limit=${
          action.payload.limit
        }&isAscending=${action.payload.ascending}&page=${
          action.payload.page
        }&sortField=${action.payload.sortField}`,
        method: 'GET',
        body: action.payload,
      }).pipe(
        map(
          (response) => fetchClinicsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.addClinic = (action$) =>
  action$.pipe(
    ofType(SAVE_CLINIC),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/clinic/`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => saveClinicResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.fetchClinicById = (action$) =>
  action$.pipe(
    ofType(CLINIC_BY_ID),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/clinic/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClinicByIdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.DeleteClinic = (action$) =>
  action$.pipe(
    ofType(DELETE_CLINIC),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/clinic/${action.payload}`,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => saveDeletedResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getNotesById = (action$) =>
  action$.pipe(
    ofType(GET_NOTES),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/note/list/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getNotesRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.addNote = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTE),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/note/`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => saveNoteRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getUsers = (action$) =>
  action$.pipe(
    ofType(GET_USERS),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/user/list/all`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getUsersResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

ClinicTableEpic.getSpeciality = (action$) =>
  action$.pipe(
    ofType(GET_SPECIALITY),
    switchMap((action) =>
      utlAjax({
        url: `${baseUrl}/crm-portal-api/v1/master/1`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getSpecialityResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default ClinicTableEpic
